/* eslint-disable react/jsx-no-target-blank */
import { Link } from "react-router-dom";
import { KTSVG } from "../../../helpers";

const SidebarFooter = () => {
  return (
    <div
      className="app-sidebar-footer flex-column-auto pt-2 pb-6 px-6"
      id="kt_app_sidebar_footer"
    >
      {/* <Link
        to="manual"
        className="btn btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100"
        data-bs-toggle="tooltip"
        data-bs-trigger="hover"
        data-bs-dismiss-="click"
        title="Manual"
      >
        <span className="btn-label">Manual</span>
        <KTSVG
          path="/media/icons/duotune/general/gen005.svg"
          className="btn-icon svg-icon-2 m-0"
        />
      </Link> */}
      <Link
        to="changelog"
        className="btn btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100"
        data-bs-toggle="tooltip"
        data-bs-trigger="hover"
        data-bs-dismiss-="click"
        title="Changelog"
      >
        <span className="btn-label">Change log v1.3.2</span>
        <KTSVG
          path="/media/icons/duotune/general/gen005.svg"
          className="btn-icon svg-icon-2 m-0"
        />
      </Link>
    </div>
  );
};

export { SidebarFooter };
